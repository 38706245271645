import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import Button from '../../layouts/Button';
import PlatformService from '../../services/PlatformService';
import ItemService from '../../services/ItemService';

const NFT = () => {
  const [paymentList, setPaymentList] = useState([]);
  const [ packetList, setPacketList ] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ config, setConfig ] = useState({});
  const [ nfts, setNfts ] = useState([])
  const Item = new ItemService();
  const Platform = new PlatformService();

  useEffect(() => {
    setLoading(true);
    let promises = [];
    promises.push(Platform.getConfig());
    promises.push(Item.getNFTs());
    Promise.all(promises).then(results => {
      console.log(results)
      setConfig(results[0].data.config);
      setNfts(results[1].data.items);
      setLoading(false);

    })
    .catch((err) => {
      setConfig({});
      setNfts([])
    });
  }, [])
  return (
    <>
      <h4 className='bold mb-5'>My NFTs</h4> 
      <div className="">

        {loading ? 
          <div className='w-100 h-100 d-center py-3'> 
            <RingLoader color='#2E5077' loading={true} size={50}/> 
          </div>  
          :
          <>
            <div className='card mb-4'>
              <div className='card-header'>
                <strong>{config.nft_token_name} ({config.nft_token_symbol}) - { config.nft_address }</strong>
              </div>
            </div>
            <div className='row'>
              { nfts.map((item, key) => ( 
              <div key={key} className='col-md-4 col-xs-6'>
                <div class="card m-3">
                  <img src={item.image} class="card-img-top" alt={item.name} />
                  <div class="card-body">
                    <h5 class="card-title">{item.name}</h5>
                    <p class="card-text">Token ID: {item.nft_token_id }</p>
                    <p class="card-text">Owner: {item.description }</p>
                    <a href={item.external_url} target='_blank' rel='noreferrer' class="custom-btn btn-coral d-block">Go to content</a>
                  </div>
                </div>
              </div>))
              }
            </div>
          </>
        }
      </div>
    </>
  )
}

export default NFT;