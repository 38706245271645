import { Outlet } from 'react-router';
import Header from './Header';
import Footer from './Footer';
import { useEffect, useState } from 'react';
import PlatformService from '../services/PlatformService';



const MainLayout = () => {

    const Platform = new PlatformService();

    const [ platformConfig, setPlatformConfig ] = useState({});

    useEffect(() => {
        Platform.getConfig().then((res) => {
            setPlatformConfig(res.data.config);
        })
        .catch((err) => {
            
        })
    }, [])

    return ( 
        <div className='main-layout'>
            <Header />
            <main className='mainLayout-main'>
                <Outlet context={[platformConfig]} />
            </main>
            <Footer />
        </div>   
    );
}
 
export default MainLayout;